

/*
 * Date: 2024
 * Description: Task from job view ( data )
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useContext , useState , useEffect , ChangeEvent  } from "react";
import { Box, Grid , IconButton } from "@mui/material"

//Context
import { MainContext } from "../../context/context";
//Icons

//Interfaces && types
import { i_task, i_task_complete, i_task_inputs_props, i_task_view_props, i_task_errors} from "../../interfaces/task.interface";
import { i_file_uploads_props , i_top_table_bar_props , i_tbl_header , i_table_v2_props, i_pagination_new , i_socket_response , i_select_search_props } from "../../interfaces/utility.interface";
import { t_display, t_method } from "../../types/types";
import { i_job_search_filter, i_top_bar_job_view_props } from "../../interfaces/jobs.interface";

//Components
import TopTaskBar from "./view/task.top.view";
import TaskTop from "./stats/task.top.view";
import TasksInputs from "./form/task.input";
import TaskCompleteView from "./view/task.complete.view";
import SelectFilter from "../utility/inputs/select.filter";
import TopTableBar from "../utility/top.table.bar";
import TblV2 from "../table/table.v2";

//Constants
import { default_job_filter, empty_complete_task, empty_task_errors, task_status } from "../../utils/constant";

//Utils
import { f_fetch, f_fetch_multiform } from "../../api/fetch";
import { f_encode_query_data , f_get_local_key , f_set_local_key } from "../../utils/utility";

//Middlewares 
import { m_validate_task } from "../../validation/main.middleware";
import { m_force_str , m_validate_number, m_validate_select , m_validate_str } from "../../validation/utility.middleware";
import { tbl_boxing , header_format } from "../../styles/tbl.styles";



const TaskView = ( props : i_task_view_props ) => {
    const { config , HandleLogout } = useContext(MainContext)
    //Main states
    const [ data , setData ] = useState<i_task_complete[]>([])
    const [ count , setCount ] = useState<number>(0)
    const [ filter , setFilter ] = useState<i_job_search_filter>(default_job_filter)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ acc_global_open, setAccGlobalOpen ] = useState<boolean>(props.config.global_task)
    const [ acc_stats_open, setAccStatsOpen ] = useState<boolean>(props.config.stats_task)
    const [ display , setDisplay ] = useState<t_display>('table')

    //Search states
    const [ search_status , setSearchStatus ] = useState<string>('Any')

    //Form states
    const [ task , setTask ] = useState<i_task_complete>({ ...empty_complete_task , job : props.target })
    const [ assets , setAssets ] = useState<string[]>([])
    const [ uploads , setUploads ] = useState<any[]>([])

    //Error states
    const [ task_errors , setTaskErrors ] = useState<i_task_errors>(empty_task_errors)
    const [ Euploads, setEuploads ] = useState<string>("")

    const socket = props.socket
    useEffect(() => {
        socket.removeAllListeners("task");
        socket.on('task', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_task_complete ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_task_complete , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            return ItemUpdated
                        } else {
                            return item
                        }
                    })
                    updatedItems.sort((a, b) => a.index - b.index);
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_task_complete ) => {
                    const data_to_update = [ ...data ]
                    const objectExists = data_to_update.some(( obj : i_task_complete ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        data_to_update.push(ItemAdded)
                        data_to_update.sort((a, b) => a.index - b.index);
                        setData(data_to_update)
                        setCount(count + 1)  
                    }
                } 
                addRow(output.item)
            }
        })
    },[socket , data , count])

    useEffect(() => {
        const f_get_tasks = async () : Promise<void> => {
            const params = f_encode_query_data(filter)
            const res = await f_fetch('/tasks/filtered/'+props.target._id +'?'+ params  , 'GET' , true , null)
            if(res.type === 'Success'){
                setData(res.data.tasks)
                setCount(res.data.count)
                return;
            }
            if(res.type === 'Unauthorized'){
                HandleLogout(props.nav);
            }else{
                props.setError({ open : true, promise : res})
            }
        }
        f_get_tasks()
        //eslint-disable-next-line react-hooks/exhaustive-deps 
    },[filter, HandleLogout])
    useEffect(() => {
        const F = task
        const E = task_errors
        if(E.title !== "" && m_force_str(F.title) === "") setTaskErrors({...task_errors, title : ''})
        if(E.description!== "" && m_validate_str(F.description)) setTaskErrors({...task_errors, description : ''})
        if(E.note!== "" && m_validate_str(F.note)) setTaskErrors({...task_errors, note : ''})
        if(E.start!== "" && m_validate_number(F.start)) setTaskErrors({...task_errors, start : ''})
        if(E.end!== "" && m_validate_number(F.end)) setTaskErrors({...task_errors, end : ''})
        if(E.type!== "" && m_validate_select(F.type)) setTaskErrors({...task_errors, type : ''})
        if(E.status!== "" && m_validate_select(F.status)) setTaskErrors({...task_errors, status : ''})
        if(E.user_id !== "" && F.user._id !== '') setTaskErrors({...task_errors, user_id : ''})
        if(E.time_estimated!== "" && m_validate_number(F.time_estimated)) setTaskErrors({...task_errors, time_estimated : ''})
        if(E.index !== "" && m_validate_number(F.index)) setTaskErrors({...task_errors, index : ''})
    },[task , task_errors])
    const handleAccordion = ( type : string ) => {
        const current_cookie = f_get_local_key('config')
        const new_cookie = { ...current_cookie}
        if(type === 'global') {
            new_cookie.global_task = acc_global_open === true ? false : true
            f_set_local_key('config', new_cookie ,  1000 * 60 * 60 * 24)
            setAccGlobalOpen(acc_global_open === true? false : true)
        } 
        if(type ==='stats') {
            new_cookie.stats_task = acc_stats_open === true ? false : true
            f_set_local_key('config', new_cookie ,  1000 * 60 * 60 * 24)
            setAccStatsOpen(acc_stats_open === true? false : true)
        }
    }
    const handleRow = ( row : i_task_complete , type : t_display) => {
        if(type === 'form'){
            setTask({...empty_complete_task , ...row , start : row.start * 1000 , end : row.end * 1000 })
            setAssets(row.initial_path)
            setDisplay('form')
        }
    }
    const clearForm = ( ) => {
        setTask(empty_complete_task)
        setAssets([])
        setUploads([])
    }
    const handleSubmitForm = async () => {
        setLoading(true)
        const F_D : i_task = {
            _id : task._id,
            title : task.title,
            job_id : props.target._id,
            status : task.status,
            description : task.description,
            time_estimated : task.time_estimated,
            type : task.type,
            start : task.start,
            end : task.end,
            user_id : task.user._id,
            initial_path : assets,
            timesheet : task.timesheet,
            note : task.note,
            path : [],
            index : task.index,
        }
        var method : t_method = 'POST'
        if( task._id !== "" ) method = 'PUT'
        const [ valid , message , errors ] = m_validate_task(F_D)
        if(valid){
            const FD = new FormData()
            if(method === 'PUT') FD.append('_id', F_D._id)
            FD.append('job_id', F_D.job_id)
            FD.append('title', F_D.title)
            FD.append('description', F_D.description)
            FD.append('time_estimated',F_D.time_estimated.toString())
            FD.append('status', F_D.status)
            FD.append('type', F_D.type)
            FD.append('start',  ( Math.round((F_D.start / 1000))).toString())
            FD.append('end', (Math.round((F_D.end /1000))).toString())
            FD.append('user_id', F_D.user_id)
            FD.append('note', F_D.note)
            FD.append('index', F_D.index.toString())
            for(let i = 0; i < F_D.timesheet.length; i++) FD.append('timesheet', JSON.stringify(task.timesheet[i]))
            for(let i = 0; i < F_D.initial_path.length; i++) FD.append('initial_path[]', F_D.initial_path[i])
            for(let i = 0; i < uploads.length; i++) FD.append('files', uploads[i])
            const res = await f_fetch_multiform('/task' , method , FD)
            if(res.type === 'Success'){
                setDisplay('table')
                setUploads([])
                clearForm();
            }
            props.setError({ open : true, promise : res})
        }else{
            if(errors === 'job_id') {
                props.setError({ open : true, promise : { type : 'Error' , message : message , data : []}})
            }else{
                setTaskErrors({...task_errors, [errors] : message})
            }
          
        }
        setLoading(false)
    }
    const handleRowsPerPage = async( value : number) => {
        setFilter({...filter , rows_per_page : value, page : 1})
    }
    const handleDisplay = ( display : t_display ) => {
        if(display === 'form'){
            clearForm()
            var value = count + 1
            setTask({ ...empty_complete_task , index : value})
        }
        setDisplay(display)
    }
    const handleSelect = ( value : ChangeEvent<HTMLInputElement> ) => {
        setSearchStatus(value.target.value)
        setFilter({...filter, status : value.target.value})
    }
    const top_bar_props : i_top_bar_job_view_props = {
        job : props.target,
        onClose : props.onClose
    }

    const top_view_props = {
        acc_open : acc_stats_open,
        handleAcc : handleAccordion,
        turn_over : 2420,
        turn_over_plus : 420,
        turn_over_last_month : '40%',
        today_task_completion : '60%',
        today_task_vs_yesterday : '15%',
        week_task_completion : '45%',
        sastifaction_score : 416,
        high_rating : 500,
        mid_rating : 400,
        low_rating : 200,
        total_ratings : 1100,
        global_rating : 4.5
    }
    const global_view_props = {
        customer : props.target.customer,
        job : props.target,
        acc_open : acc_global_open,
        handleAcc : handleAccordion,
    }
    const file_uploads : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets: setAssets,
        Euploads : Euploads,
        setEuploads: setEuploads,
        type : 'form',
        path : '/webapi/uploads/task/',
        title : 'File uploads',
        setApiError: props.setError,
        max : 20,
        mobile : props.mobile,
        file_type : 'photo',
        language : 'en'
    }
    const task_input_props : i_task_inputs_props = {
        form : task,
        setForm : setTask,
        errors : task_errors,
        callback : handleSubmitForm,
        mode : props.mode,
        dev : props.dev,
        onClose : () => setDisplay('table'),
        users : props.users,
        uploads : file_uploads,
        loading : loading
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Tasks'
    }
    const select_status_props : i_select_search_props = {
        label : 'Filter by status',
        selected : search_status,
        handleSelect : (value :  ChangeEvent<HTMLInputElement>) => handleSelect(value),
        data : task_status,
        placeholder : 'Search',
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Tasks',
        add : true,
        callback : () => handleDisplay('form'),
        inputs : [<SelectFilter {...select_status_props} /> ]
    }
    const task_headers : i_tbl_header[] = [
        { value : "Task name" , css : header_format , portion : 4 ,  icon: (
            <IconButton 
                onClick={() => setFilter({...filter, sort_order: filter.sort_order === 'ASC' ? 'DESC' : 'ASC'})}
            >
                <img 
                    alt="Sort order img" 
                    src={filter.sort_order === 'ASC' ? './images/arrow-up.svg' : './images/arrow-down.svg'}
                />
            </IconButton>
        )},
        { value : "Start date" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "End date" , css : header_format , portion : 1.5, mt : 0.8  },
        { value : "Progress" , css : header_format , portion : 2.5, mt : 0.8 },
        { value : "Assignated to" , css : header_format , portion : 1.5 , mt : 0.8 },
        { value : "Actions" , css : header_format , portion : 1, mt : 0.8  },
    ]
    const table_v2_props : i_table_v2_props = {
        data : data,
        title : 'Please start by adding a new task',
        loading : loading,
        headers : task_headers,
        callback : handleRow,
        setApiError : props.setError,
        row_model : "task",
        pagination : pagination_props
    }
    return (
        <Box sx={{ minHeight : '91vh' , width : '100%'}}>
            
                { display === 'table' &&
                    <Grid container>
                        <Grid item xs={12} sx={{ display : 'flex' , marginBottom : '1vh' , borderBottom : '1px solid ' + config.primary_color , padding : '8px'}}>
                            <TopTaskBar {...top_bar_props }/>
                        </Grid>
                        <Grid item xs={12}>
                            <TaskTop {...top_view_props}></TaskTop>
                        </Grid>
                        <Grid item xs={12}>
                            <TaskCompleteView {...global_view_props}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TopTableBar {...table_tool_bar_props }/>
                            <Box sx={tbl_boxing}>
                                <TblV2 {...table_v2_props}></TblV2>
                            </Box>
                        </Grid>
                    </Grid>
                }
                {display === 'form' && 
                    <Box>
                        <TasksInputs {...task_input_props}></TasksInputs>
                    </Box>
                }
            
        </Box>
    )
}

export default TaskView