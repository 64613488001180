/*
 * Date: 2024
 * Description: Timesheet management
 * Author: Philippe Leroux @ skitsc
 */

import { Box , IconButton } from "@mui/material"
import { ReactElement , useState , useContext, useEffect , ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"

//Interfaces && types
import { i_initial_props , i_alert_props , i_file_uploads_props , i_socket_response , i_search_filter, i_top_table_bar_props, i_basic_search_props, i_table_v2_props , i_tbl_header , i_pagination_new, i_period_data } from "../interfaces/utility.interface"
import { t_display , t_transition_event } from "../types/types"
import { i_user } from "../interfaces/user.interface"

//Components
import Footer from "../components/utility/footer"
import AlertDialog from "../components/utility/alert"
import TopTableBar from "../components/utility/top.table.bar"
import TblV2 from "../components/table/table.v2"
import PayrollInputs from "../components/timesheet/form/timesheet.inputs"

//Context
import { ThemeContext , MainContext } from "../context/context"
import { SocketContext } from "../context/socket.context"

//Utils
import { f_empty_promise ,f_encode_query_data , CheckEnv, getPayAllPeriods } from "../utils/utility"
import { f_fetch } from "../api/fetch"


//Constants
import { default_filter, default_timesheet_complete, default_timesheet_errors } from "../utils/constant"
import SearchFilter from "../components/utility/inputs/search.filter"


//Styles
import { header_row, tbl_boxing } from "../styles/tbl.styles"
import { i_timesheet_complete, i_timesheet_errors, i_timesheet_inputs_props } from "../interfaces/timesheet.interface"

//Constants
const Timesheet = ( props : i_initial_props ) : ReactElement => {

    const { mode } = useContext(ThemeContext);
    const { HandleLogout } = useContext(MainContext)
    const socket = useContext(SocketContext);
    const nav = useNavigate();
    const [ dev ] = useState<boolean>(CheckEnv);
    
    const [ data , setData ] = useState<i_timesheet_complete[]>([])
    const [ periods ] = useState<i_period_data[]>(getPayAllPeriods(2024, true))
    const [ users , setUsers ] = useState<i_user[]>([])
    const [ count , setCount ] = useState<number>(1)
    const [ search , setSearch ] = useState<string>("")
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter)
    const [ api_errors, setApiError ] = useState({ open : false, promise : f_empty_promise()})
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ modal_type ] = useState<t_transition_event>('delete')
    const [ target, setTarget ] = useState<any>({})
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ assets , setAssets ] = useState<any>([])
    const [ uploads , setUploads ] = useState<any>([])

    //Form states
    const [ timesheet , setTimesheet ] = useState<i_timesheet_complete>(default_timesheet_complete)
    const [ timesheets_errors ] = useState<i_timesheet_errors>(default_timesheet_errors)
    const [ Euploads , setEuploads ] = useState<string>("")

    useEffect(() => {
        socket.removeAllListeners("timesheet");
        socket.on('timesheet', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_timesheet_complete ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_timesheet_complete , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            console.log(ItemUpdated)
                            return ItemUpdated
                        } else {
                            return item
                        }
                    })
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_timesheet_complete ) => {
                    const data_to_update = [ ...data ]
                    const objectExists = data_to_update.some(( obj : i_timesheet_complete ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        data_to_update.push(ItemAdded)
                        setData(data_to_update)    
                        setCount(count +1 )
                    }
                } 
                addRow(output.item)
            }
        })
    },[socket , data , count])

    useEffect(() => {
        const f_get_users = async () => {
            const res = await f_fetch('/users', 'GET', true, null)
            if(res.type === 'Success')  setUsers(res.data)
        }
        f_get_users()
    },[])
    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true)
    //         const query = f_encode_query_data(filter)
    //         const res = await f_fetch('/timesheet/filtered?'+query , 'GET' , true , null)
    //         if(res.type === 'Success'){
    //             setData(res.data.timesheets)
    //             setCount(res.data.count)
    //         }else{
    //             if(res.type === 'Unauthorized'){
    //                 HandleLogout(nav)
    //             }else{
    //                 setApiError({ open : true, promise : res})
    //             }
    //         }
    //         setLoading(false)
    //     }
    //     fetchData()
    // },[HandleLogout , nav , filter])
    const handlePeriod = () : string => {
        const date = new Date();
        const currentDay = date.getDay();
        const mondayDate = new Date(date);
        mondayDate.setDate(date.getDate() - currentDay + (currentDay === 0 ? -6 : 1));
        const fridayDate = new Date(mondayDate);
        fridayDate.setDate(mondayDate.getDate() + 4);
        const mondayDateString = mondayDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' , year : 'numeric'});
        const fridayDateString = fridayDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' , year : 'numeric'});

        return `Period: ${mondayDateString} - ${fridayDateString}`;
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const handleSubmit = async() => {
    }
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiError({open : false , promise :f_empty_promise()}),
        type : 'simple'
    }

    const handleRow = ( value : any , type : t_display ) : void => {
        setTarget(value)
 
    }
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Payrolls',
        add : true,
        callback : () => setDisplay('form'),
        inputs : [<SearchFilter {...search_input_props} />]
    }
    const timesheet_headers : i_tbl_header[] = [
        { value : "Employee name" , css : { ...header_row, width : '15%' } , portion : 4 ,  icon: (
            <IconButton 
                onClick={() => setFilter({...filter, sort_order: filter.sort_order === 'ASC' ? 'DESC' : 'ASC'})}
            >
                <img 
                    alt="Sort order img" 
                    src={filter.sort_order === 'ASC' ? './images/arrow-up.svg' : './images/arrow-down.svg'}
                />
            </IconButton>
        )},
        { value : "Pay status" , css : { ...header_row, width : '20%' } , portion : 1.5 },
        { value : "Period" , css : { ...header_row, width : '20%' } , portion : 2 },
        { value : "Hours" , css : { ...header_row, width : '15%' } , portion : 1},
        { value : "Net timesheet" , css : { ...header_row, width : '10%' } , portion : 1 },
        { value : "Taxes" , css : { ...header_row, width : '10%' } , portion : 1 },
        { value : 'Action', css : header_row, portion : 1.5  }
    ]
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
        const new_filter = { ...filter }
        new_filter.page = page
        setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Payrolls'
    } 
    const table_v2_props : i_table_v2_props = {
        data : [],
        title : 'Please start by adding a new timesheet',
        loading : loading,
        headers : timesheet_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "timesheet",
        pagination :  pagination_props
    }
    const file_uploads : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets: setAssets,
        Euploads : Euploads,
        setEuploads: setEuploads,
        type : 'form',
        path : '/webapi/uploads/jobs/',
        title : 'File uploads',
        setApiError: setApiError,
        max : 20,
        mobile : props.mobile,
        file_type : 'photo',
        language : props.language
    }
    const timesheet_inputs_props : i_timesheet_inputs_props = {
        form : timesheet,
        setForm : setTimesheet,
        errors : timesheets_errors,
        mode : mode,
        uploads : file_uploads,
        callback : handleSubmit,
        onClose : setDisplay,
        title : '',
        loading : loading,
        dev : dev,
        users : users,
        periods : periods
    }
    return (
        <Box>
            <Box sx={{ minHeight : '91.5vh'}}>
            {display === 'table' &&
                <Box >
                   <TopTableBar {...table_tool_bar_props}></TopTableBar>
                   <Box sx={ tbl_boxing }>
                        <TblV2 { ...table_v2_props }></TblV2>
                   </Box>
                </Box>
            }
            {display === 'form' && 
                <Box>
                   <PayrollInputs {...timesheet_inputs_props}></PayrollInputs>
                </Box>
            }
            </Box>
            <AlertDialog {...alert_props} />
             
            <Footer type={'off'}/>
        </Box>
    )
}


export default Timesheet