
/*
 * Date: 2025
 * Description: Resable alert component
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useEffect, useState } from'react';
import { Snackbar , Alert } from '@mui/material';

//Interfaces && types
import { i_alert_props } from '../../interfaces/utility.interface';

//Utilitys
import { f_empty_promise } from '../../utils/utility';

const AlertDialog = ( props : i_alert_props ) => {
    const P = props.event.promise
    const [ open , setOpen ] = useState<boolean>(props.event.open);
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.handleClose({ open: false, promise: f_empty_promise() });
        }, 500);
    }
    useEffect(() => {
        if(props.event.promise.message !== '') setOpen(true);
    },[props.event.promise])

    useEffect(() => {
        if(open)setTimeout(() => { setOpen(false); }, 8000);
    },[open])
    return (
        <Snackbar sx={[{ marginTop : '5vh' , border : '1px solid gray'} , props.type === 'simple' && { marginLeft : '100px'}]}  anchorOrigin={{ vertical: "top", horizontal: "center" }} open={open} autoHideDuration={6000} key={"top center"}>
            <Alert onClose={() => handleClose()} severity={P.type === "Success" ? 'success' : P.type === 'Failed' ? "error" : 'warning'}>
                {P.message}
            </Alert>
        </Snackbar>
    )
}
export default AlertDialog;