
/*
 * Date: 2024
 * Description: Customer cars show components ( handle CRUD )
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { useState , useEffect, useContext } from "react"
import { Grid , Box, Typography , Modal} from "@mui/material"


//Components
import ToolBarV2 from "./menu/tool.bar"
import CarInputs from "../cars/car.inputs"
import CarLayout from "../cars/car.layout"
import RegInputs from "../cars/registration/registration.inputs"
import ShellModal from "../modal/modal"
import CarMore from "../cars/car.more"
//Constants
import { empty_car_insurance, empty_car_registration, empty_customer_car , customer_car_errors , insurance_errors, registration_errors } from "../../utils/constant"

//interfaces && types 
import { i_customer_car, i_customer_view_props , i_customer_car_errors,  i_customer_car_registration_props,
     i_customer_car_form_props, i_customer_car_insurance_props, t_customer_car_valid_type, i_customer_car_layout_props, t_customer_modal_body, 
     i_insurance,
     i_insurance_errors,
     i_registration,
     i_registration_errors} from "../../interfaces/customer.interface"
import { f_valid_car , f_valid_car_insurance , f_valid_car_registration } from "../../utils/validator"
import { t_display, t_event, t_method } from "../../types/types"
import { i_file_uploads_props, i_shell_modal, i_tool_bar_props } from "../../interfaces/utility.interface"

//Middlewares
import { m_validate_customer_car } from "../../validation/main.middleware"

//Fetch
import { f_fetch, f_fetch_multiform } from "../../api/fetch"

//Theming
import { ThemeContext } from "../../context/context"

//Middlewares
import { m_force_str, m_validate_number, m_validate_str } from "../../validation/utility.middleware"
import InsurancesInputs from "../cars/insurance/insurance.inputs"
import InsurancesView from "../cars/insurance/insurance.view"
import RegistrationsView from "../cars/registration/registration.view"

const CustomerCars = ( props : i_customer_view_props ) => {

    //Context
    const { mode } = useContext(ThemeContext)
    //Main states
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ search , setSearch ] = useState<string>('')
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ target , setTarget ] = useState<i_customer_car>(empty_customer_car)

    //Modal gestures
    const [ open , setOpen ] = useState<boolean>(false)
    const [ modal_body , setModalBody ] = useState<t_customer_modal_body>('none')

    //Car FORM
    const [ car , setCar] = useState<i_customer_car>({...empty_customer_car , customer_id : props.target._id})
    //Car Error fields
    const [ car_errors , setCarErrors] = useState<i_customer_car_errors>(customer_car_errors)

    //Uploads
    const [ uploads , setUploads ] = useState<any>([])
    const [ assets , setAssets ] = useState<string[]>([]);
    const [ Euploads , setEuploads] = useState<string>("")
    //Car Insurance FORM
    const [ i_pos , setIPos ] = useState<number>(0);
    const [ insurance , setInsurance ] = useState<i_insurance>(empty_car_insurance)
    const [ insurances_errors , setInsurancesErrors] = useState<i_insurance_errors>(insurance_errors)

    //Car Registration FORM
    const [ r_pos , setRPos ] = useState<number>(0)
    const [ registration , setRegistration ] = useState<i_registration>(empty_car_registration)
    const [ registrations_errors , setRegistrationErrors ] = useState<i_registration_errors>(registration_errors)
    //Car reg Errors

    useEffect(() => {
        for(let i = 0; i < props.customer_cars.length; i++){
            if(props.customer_cars[i]._id === target._id){
                handleTarget(props.customer_cars[i] , 'car')
                setTarget(props.customer_cars[i])
                break;
            }
        }
    },[props.customer_cars , target._id])
    const handleDisplay = ( value : t_display) => setDisplay(value)
    const handleClearCar = () => {
        setTarget(empty_customer_car)
        setCar(empty_customer_car)
    } 
    const handleClearInsurance = () => {
        setIPos(0)
        setInsurance(empty_car_insurance)
        setAssets([])
        setUploads([])
    }
    const handleClearCarRegistration = () => {
        setRPos(0)
        setAssets([])
        setRegistration(empty_car_registration)
        setUploads([])
    }
    const handleTarget = ( value : i_customer_car | i_insurance | i_registration , type : 'car' | 'registration' | 'insurance' ) => {
        if(type === 'car' && f_valid_car(value)){
            setCar(value)
        }
        if(type === 'registration' && f_valid_car_registration(value)){

        }
        if(type === 'insurance' && f_valid_car_insurance(value)){

        }
     
    }
    const handleSubmitCar = async( type : t_customer_car_valid_type ) => {
        setLoading(true);
        var img_ok = true;
        console.log(registration , type)
        const [ valid , error , field ] =  type === 'registration' ? m_validate_customer_car( registration , type ) : type === 'insurance' ? m_validate_customer_car( insurance, type ) : m_validate_customer_car( car , type )
        if( type === 'registration' || type === 'insurance' ){
            if(uploads.length === 0){
                setEuploads("Please upload at least one image")
                img_ok = false;
            }
        }
        console.log(valid,error, field)
        if(valid && img_ok){
            var method : t_method = 'POST'
            if(type === 'car'){
                if(car._id !== '') method = 'PUT'
                const clean_car = { ...car , customer_id : props.target._id }
                const res = await f_fetch('/customer/car' , method , true , clean_car)
                    props.setError({ open : true, promise : res})
                if(res.type === 'Success'){
                    handleClearCar()
                    setDisplay('table')
                }
            }else{
                const FD = new FormData()
                
                FD.append('_id', target._id)
                for(var x = 0; x < uploads.length; x++){
                    FD.append('files', uploads[x])
                }
                if(type === 'registration'){
                    FD.append('registration_number' , registration.registration_number)
                    FD.append('no_cert', registration.no_cert)
                    FD.append('expires', Math.floor(registration.expires.valueOf() / 1000).toString())
                    FD.append('delivered', Math.floor(registration.delivered.valueOf() / 1000).toString())
                    FD.append('position' , r_pos.toString())
                }
                if(type === 'insurance'){
                    FD.append('expires', Math.floor(insurance.expires.valueOf() / 1000).toString())
                    FD.append('delivered', Math.floor(insurance.delivered.valueOf() / 1000).toString())
                    FD.append('position' , i_pos.toString())
                    FD.append('company_name', insurance.company_name)
                    FD.append('no_police', insurance.no_police)
                    FD.append('courtier', insurance.courtier)
                    FD.append('new_value', insurance.new_value.toString())
                    if(insurance.new_value_code!== undefined)
                    FD.append('new_value_code', insurance.new_value_code)
                }
                const fd_res = await f_fetch_multiform('/customer/car/'+type, method, FD)
                props.setError({ open : true, promise : fd_res})
                if( fd_res.type === 'Success' && open ){
                    setOpen(false)
                    setModalBody('none')
                    handleClearCarRegistration()
                    handleClearInsurance()
                }
            }
            
        }else{
            if(type === 'car'){
                setCarErrors({...car_errors, [field] : error})
            }
            if(type === 'registration'){
                setRegistrationErrors({...registration_errors, [field] : error})
            }
            if(type === 'insurance'){
                setInsurancesErrors({...insurance_errors, [field] : error})
            }
        }
        setLoading(false)
    }
    useEffect(() => {
        const F = registration;
        const E = registration_errors;
        if(E.registration_number !== '' && m_force_str(F.registration_number) === '') setRegistrationErrors({...registrations_errors, registration_number : ''})
        if(E.no_cert!== '' && m_validate_str(F.no_cert))setRegistrationErrors({...registrations_errors, no_cert : ''})
    },[registration , registrations_errors])
    useEffect(() => {
        const F = insurance
        const E = insurances_errors;
        if(E.company_name !== '' && m_force_str(F.company_name) === '') setInsurancesErrors({...insurances_errors, company_name : ''})
        if(E.no_police!== '' && m_force_str(F.no_police) === '')setInsurancesErrors({...insurances_errors, no_police : ''})
        if(E.courtier!== '' && m_validate_str(F.courtier)) setInsurancesErrors({...insurances_errors, courtier : ''})
        if(E.new_value_code!== '' &&( F.new_value_code !== undefined ? m_validate_str(F.new_value_code) : false)) setInsurancesErrors({...insurances_errors, new_value_code : ''})
    },[insurance, insurances_errors])
    useEffect(() => {
        const F = car;
        const E = car_errors;
        if(E.year!== '' && m_validate_number(F.year))setCarErrors({...car_errors, year : ''})
        if(E.make!== '' && m_force_str(F.make) === '') setCarErrors({...car_errors, make : ''})
        if(E.model!== '' && m_force_str(F.model) === '') setCarErrors({...car_errors, model : ''})
        if(E.color!== '' && m_force_str(F.color) === '') setCarErrors({...car_errors, color : ''})
        if(E.trim!== '' && m_force_str(F.trim) === '') setCarErrors({...car_errors, trim : ''})
        if(E.color_code!== '' && m_validate_str(F.color_code)) setCarErrors({...car_errors, color_code : ''})
        if(E.color_code_two!== '' && m_validate_str(F.color_code_two)) setCarErrors({...car_errors, color_code_two : ''})
        if(E.kilo!== '' && m_validate_str(F.kilo))  setCarErrors({...car_errors, kilo : ''})
        if(E.vin!== '' && m_force_str(F.vin) === '') setCarErrors({...car_errors, vin : ''})
    },[car , car_errors])
        
    const handleSearch = () => {

    }
    const handleRegistrationForm = ( obj : i_registration ) : void => {
        setRegistration(obj);
        setRPos(obj.position);
        setUploads([])
        setAssets([])
    }
    const handleModalTransition = ( body : t_customer_modal_body , car : i_customer_car , event : t_event) => {
        setModalBody(body) 
        if(body === 'registration' && event === 'Add'){
            handleRegistrationForm(empty_car_registration)
        }
        setOpen(true)
    }
    const handleCarSelection = ( car : i_customer_car, type : t_display , event : t_customer_modal_body) => {
        setTarget(car)
        if(event !== 'none'){
            handleModalTransition(event , car , 'Add')
        }else{
            if(type === 'form'){
                handleTarget(car, 'car')
            }
            handleDisplay(type)
        }
        
    }
    const handleAdd = ( value : t_display , type : 'car' | 'registration' | 'insurance' ) => {
        const obj = type === 'car' ? empty_customer_car : type === 'insurance' ? empty_car_insurance : empty_car_registration
        handleTarget(obj , type)
        handleDisplay(value)
    }
    const handleMoreView = ( event : t_customer_modal_body ) => {
        handleModalTransition( event , target , 'Add')

    }
    const f_return_title = () : string => {
        var sub_title : string = modal_body === 'view_insurance' || modal_body === 'view_registration' ? 'View' : 'Add'
        var title = modal_body === 'registration'? 'Registration' : modal_body === 'insurance'? 'Insurance' : modal_body === 'notes' ?
         'Notes' : modal_body === 'logs' ? "Logs" : modal_body === 'view_insurance' ? 'Latest insurance added' : modal_body === 'view_registration' ? "Latest registration added" : 'to be defined..'
        return sub_title + ' ' + title
    }
    const toolbar_props : i_tool_bar_props = {
        searchable : props.customer_cars.length > 0 ? true : false,
        search : search,
        placeholder : "Search by car id , status",
        split : true,
        setSearch : setSearch,
        callbackSearch : () => { handleSearch()},
        callbackAdd : () => { handleAdd('form' , 'car')},
    }
    const car_form_props : i_customer_car_form_props = {
        form : car,
        setForm : setCar,
        errors : car_errors,
        loading : loading,
        callback : ( type : t_customer_car_valid_type) => { handleSubmitCar(type)},
        return : () => { handleDisplay('table')},
        type : 'full',
        view : false,
        dev : props.dev
    }
    const car_layout_props : i_customer_car_layout_props = {
        cars : props.customer_cars,
        loading : loading,
        callback : ( C : i_customer_car , type : t_display , event : t_customer_modal_body) => { handleCarSelection( C , type , event)},
        return : ( event : t_display) => { handleDisplay(event) },
    }
    const insurance_form_uploads : i_file_uploads_props = {
        assets : assets,
        setAssets : setAssets,
        uploads : uploads,
        setUploads : setUploads,
        Euploads : Euploads,
        setEuploads : setEuploads,
        setApiError :  props.setError,
        max : 2,
        title : 'Insurances pictures',
        type : 'modal',
        path : '/assets/insurance',
        mobile : props.mobile,
        file_type : 'photo',
        language : 'en'
    }
    const registration_form_uploads : i_file_uploads_props = {
        assets : assets,
        setAssets : setAssets,
        uploads : uploads,
        setUploads : setUploads,
        Euploads : Euploads,
        setEuploads : setEuploads,
        setApiError :  props.setError,
        max : 2,
        title : 'Registration pictures',
        type : 'modal',
        path : '/assets/license',
        mobile : props.mobile,
        file_type : 'photo',
        language : 'en'
    }
    const registration_form_props : i_customer_car_registration_props = {
        form : registration,
        setForm : setRegistration,
        errors : registration_errors,
        loading : loading,
        uploads : registration_form_uploads,
        callback : () => handleSubmitCar('registration'),
        return : () => { open ? setOpen(false) : handleDisplay('table') },
        type: 'single',
        view : false,
        dev : props.dev
    }
    const insurance_form_props : i_customer_car_insurance_props = {
        form : insurance,
        setForm : setInsurance,
        errors : insurance_errors,
        loading : loading,
        uploads : insurance_form_uploads,
        callback : () => { handleSubmitCar('insurance')},
        return : () => { open ? setOpen(false) : handleDisplay('table')},
        type : 'single',
        view : false,
        dev : props.dev
    }
    const car_more_props = {
        target : target,
        registration_form : registration_form_props,
        insurance_form : insurance_form_props,
        callback : handleMoreView,
        return : () => { handleDisplay('table')},
    }
    const car_modal_props : i_shell_modal = {
        open : open,
        handleClose : () => setOpen(false),
        title : f_return_title(),
        mode : mode,
        children : modal_body === 'registration' ? <RegInputs {...registration_form_props} /> : modal_body === 'insurance' ? <InsurancesInputs {...insurance_form_props } />
        : modal_body === 'view_insurance' ? <InsurancesView insurance={target.insurance[0]} />: modal_body === 'view_registration' ? <RegistrationsView registration={target.registration[0]} /> : <></>
    }
    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sx={{ }}>
                    <Box sx={{ padding : '1vh'}}>
                        { display === 'table' &&
                            <Box>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography sx={{ fontSize : 20 }}>All cars</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ToolBarV2 {...toolbar_props}></ToolBarV2>
                                    </Grid>
                                </Grid>
                                <Box sx={{ overflowY: 'scroll' , maxHeight : '84vh' }}>
                                    { props.customer_cars.length > 0 ?
                                        <CarLayout {...car_layout_props}/>
                                        : 
                                        <Box sx={{ display : 'flex' , justifyContent : 'center' , padding : '1vh'}}>
                                            <Typography sx={{ fontSize : 20}}>No cars yet , start by adding a new car..</Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        }
                        { display === 'form' &&
                            <Box>
                                <CarInputs {...car_form_props} />
                            </Box>
                        }
                        { display === 'more' && 
                            <Box>
                            <CarMore {...car_more_props}></CarMore>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
            <Modal open={open} onClose={() => setOpen(false)} children={<ShellModal {...car_modal_props} />}></Modal>
        </Box>
    )
}

export default CustomerCars;