/*
 * Date: 2023
 * Description: License management inputs
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { ReactElement , DragEvent, ChangeEvent , useCallback , useRef, useContext } from "react";
import { Grid , TextField, Typography , Box , IconButton , Tooltip, MenuItem } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

//Components
import UploadsInputs from "../../utility/upload.box";
//Interfaces && types
import { i_customer_form_props } from "../../../interfaces/customer.interface";
import { i_img_display } from "../../../interfaces/utility.interface";
import { t_file_types } from "../../../types/types";

//Styles
import { input_base, sub_title , bottom_line } from "../../../styles/form.styles";

//Middlewares
import { m_picture_validation } from "../../../validation/utility.middleware";
//Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { provinces } from "../../../utils/constant";
import { MainContext } from "../../../context/context";

//Api url
const api_url = process.env.REACT_APP_API_URL

const icon_style = {
    position: 'absolute',
    top: 4,
    right: 4,
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', 
    },
};
const setUrl = (variable: File | string): string => {
    if (variable instanceof File) {
        return URL.createObjectURL(variable);
    } else if (typeof variable === 'string') {
        return variable;
    }
    return '' 
};

const LicenseInputs = ( props : i_customer_form_props ) : ReactElement => {
    const { config } = useContext(MainContext)
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const F = props.form
    const E = props.errors
    const S = props.files
    const handleDate = (date : Dayjs | null , type : 'birth_date' | 'expiration_date' | 'delivery_date') => {
        if(date !== null)  props.setForm({...F , licence : { ...F.licence ,  [type] : date.valueOf() }})
    }
    const getFileNames = (files: File[]) : string =>{
        return files.map((file : File) => file.name).join(', ');
    }
    const handleRemove = ( file : string | File, type : t_file_types ) => {
        if(type === 'Upload'){
            const new_assets = [...S.assets]
            const filteredArray = new_assets.filter(item => item !== file);
            S.setAssets(filteredArray)
        }
        if(type === 'Local'){
            const new_uploads = [...S.uploads]
            const filteredArray = new_uploads.filter(item => item !== file);
            S.setUploads(filteredArray)
        }
    }
    const handleFiles = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const validFiles = Array.from(selectedFiles).filter(file => m_picture_validation(file , 'photo'));
            const invalidFiles = Array.from(selectedFiles).filter(file => !m_picture_validation(file, 'photo'));
            const total = validFiles.length + S.uploads.length + S.assets.length
            if(total <= S.max){
                if (validFiles.length > 0) {
                    const updatedUploads = [...S.uploads, ...validFiles];
                    S.setUploads(updatedUploads);
                    S.setEuploads('');
                } 
                if(invalidFiles.length > 0) {
                    var str = getFileNames(invalidFiles)
                    S.setEuploads('File extension is invalid please chose one of : (jpg, jpeg, png, ou gif). Files : '+str+ ' has not been uploaded.');
                }
            }else{
                console.log('Too many files')
                S.setApiError({ open : true, promise : { type : 'Failed' , message : "Please select only " + S.max + " files in total !"  , data : []} });
            }
        }
      }, [S] );
      const ImgCards = ( props : i_img_display ) => {
        var name : string = '';
        var path : string = '';
        const file_set = setUrl(props.img);
        if(props.type === 'Upload') {
            name = file_set
            path = api_url + props.path + props.img
        }else{
            name = 'uploads..'
        }
        const f_return_tooltip = ( type : 'img' | 'delete') => {
            const isFile = ( variable : string | File ) : variable is File => {
                return variable instanceof File;
            }
            if(type === 'delete'){
                if(name === 'uploads..' && isFile(props.img)){
                    return 'Remove new uploaded file ' + props.img.name
                }else{
                    return 'Remove ' +name+ ' ?'
                } 
            }
            if(type === 'img'){
                if(name === 'uploads..' && isFile(props.img)){
                    return props.img.name
                }else{
                    return name
                } 
            }
            return ''
    
        }
        const form_img_card_style = { Height: 250, Width: 210, overflow: 'auto', position: 'relative' , margin : '1em' }
        const img_style_form = { width: 200, height: 200, display: 'block',  cursor : 'help' };
        return ( 
            <Box sx={[form_img_card_style, props.exists && { border : '1px solid blue'}]}>
                <Tooltip title={f_return_tooltip('img')}>
                    <Box alt={name} sx={img_style_form} component={'img'} src={props.type === 'Local' ? setUrl(props.img) : path}></Box>
                </Tooltip>
                <Tooltip title={f_return_tooltip('delete')}>
                    <IconButton sx={icon_style} onClick={() => props.handleRemove(props.img, props.type)}><HighlightOffIcon/></IconButton>
                </Tooltip>
            </Box>
        )
    }
    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    
        // Handle dropped files here
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const validFiles = Array.from(files).filter(file => m_picture_validation(file , 'photo'));
            const invalidFiles = Array.from(files).filter(file => !m_picture_validation(file, 'photo'));
            const total = validFiles.length + S.uploads.length + S.assets.length
            if(total <= S.max){
                if (validFiles.length > 0) {
                    const updatedUploads = [...S.uploads, ...validFiles];
                    S.setUploads(updatedUploads);
                    S.setEuploads('');
                } 
                if(invalidFiles.length > 0) {
                    var str = getFileNames(invalidFiles)
                    S.setEuploads('File extension is invalid please chose one of : (jpg, jpeg, png, ou gif). Files : '+str+ ' has not been uploaded.');
                }
            }else{
                console.log('Too many files')
                S.setApiError({ open : true, promise : { type : 'Failed' , message : "Please select only " + S.max + " files in total !"  , data : []} });
            }
        }
    };
    const handleBoxClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
    }
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name , value } = event.target;
        props.setForm({ ...F, licence : { ...F.licence, [name] : value }});
    };
    const form_style = {display: 'flex', flexWrap: 'wrap', gap: '1em' , justifyContent : 'center' , border : '1px solid gray' }
    return (
        <Box>
            <Typography sx={{ marginTop : '2vh' , fontSize : 20 , fontWeight : 800}}> Customer driver license</Typography>
            <Typography sx={{ marginBottom : '2vh' , color : 'gray'}}>Some gray text here...</Typography>
            <Grid container sx={{ marginBottom : '1vh'}}>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>License number* / province*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { maxWidth : '300px'}]} size="small" name={'licence_number'} error={E.licence.licence_number !== ''} helperText={E.licence.licence_number}  value={F.licence.licence_number} onChange={handleChange}/>
                    <TextField sx={[input_base , { maxWidth : '200px'}]} name={'licence_province'} value={F.licence.licence_province} onChange={handleChange} size="small" select>
                        {provinces.map((provinces , index ) => (
                            <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Reference number*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <TextField sx={[input_base , { maxWidth : '300px'}]} size="small" name={'reference_number'} error={E.licence.reference_number !== ''}helperText={E.licence.reference_number} value={F.licence.reference_number} onChange={handleChange}/>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>License dates*</Typography>
                </Grid>
                <Grid item xs={9}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker sx={[input_base , { width : '200px'}]} label="Delivery date"  value={dayjs(F.licence.delivery_date)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'delivery_date')}/>
                        <DatePicker sx={[input_base , { width : '200px'}]} label="Expiration date" value={dayjs(F.licence.expiration_date)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'expiration_date')}/>
                        <DatePicker sx={[input_base , { width : '200px'}]} label="Birth date" value={dayjs(F.licence.birth_date)} onChange={(newValue : Dayjs | null) => handleDate(newValue , 'birth_date')}/>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                {/* <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
                <Grid item xs={3} sx={{ display : 'flex'}}>
                    <Typography sx={sub_title}>License images ( back and front ) *</Typography>
                    {}
                    <IconButton onClick={handleBoxClick} sx={{ height : '30px'}}>
                        <AddAPhotoIcon color={'primary'} />
                    </IconButton>
                </Grid> */}
                <UploadsInputs {...S} />
                {/* <Grid item xs={9}>
                
                    <Box sx={[{ width : '800px' } , S.Euploads !== '' ? { border : '2px solid red'} : {  }]}>
                        <Box sx={{ width : "100%" }}>
                            <Box sx={form_style} onDragOver={handleDragOver} onDrop={handleDrop} >
                                <>
                                { S.assets.length > 0 || S.uploads.length > 0 ? 
                                <>
                                    {
                                        S.assets.map((img, index) => (
                                            <Box key={index}>
                                                <ImgCards index={index} img={img} type={'Upload'} handleRemove={handleRemove} exists={true} path={S.path} style_type={S.type}/>
                                            </Box>
                                        ))}{
                                    }
                                    {
                                        S.uploads.map((file, index) => (
                                            <Box key={index}>
                                                <ImgCards index={index} img={file} type={'Local'} handleRemove={handleRemove} exists={false} path={S.path} style_type={S.type}/>
                                            </Box>
                                        ))
                                    }
                                </>
                                : 
                                <Grid container sx={{ marginTop : '3vh' , display : 'flex'}}>
                                    <Grid item xs={12} sx={{ display : 'flex' , justifyContent : 'center' }}>
                                        <Typography  sx={{ color : config.primary_color !== '' ? config.primary_color : 'red' , marginRight: '8px' , cursor : 'pointer'}}onClick={handleBoxClick}>Click to upload</Typography>
                                        <Typography sx={{ color : 'gray'}}> { "or drag and drop "}</Typography>         
                                    </Grid>
                                    <Grid item xs={12} sx={{ display : 'flex' , justifyContent : 'center'}}>                 
                                        <Box sx={{ color : 'gray'}}> SVG , PNG , JPG or GIF ( max 800x400px )</Box>
                                    </Grid>
                                    
                                </Grid>
                                }
                                <input type="file" ref={fileInputRef} onInput={handleFiles} multiple hidden/>
                                </>
                            </Box>
                        </Box>
                        {S.Euploads !== '' && <Typography sx={{ textAlign : 'center' , color : 'red' , textDecoration : 'underline'}}>{S.Euploads}</Typography> }
                    </Box>
                    
                </Grid> */}
            </Grid>
        </Box>
    ) 
}

export default LicenseInputs;