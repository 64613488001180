/*
 * Date: 2024
 * Description: Payroll
 * Author: Philippe Leroux @ skitsc
 */

import { Box , Modal , IconButton } from "@mui/material"
import { ReactElement , useState , useContext, useEffect , ChangeEvent } from "react"
import { useNavigate } from "react-router-dom"

//Interfaces && types
import { i_initial_props , i_alert_props , i_file_uploads_props , i_socket_response , i_shell_modal , i_fragmented_body, i_prompt_modal_props, i_search_filter, i_top_table_bar_props, i_basic_search_props, i_table_v2_props , i_tbl_header , i_pagination_new } from "../interfaces/utility.interface"
import { t_display , t_transition_event } from "../types/types"
import { i_user } from "../interfaces/user.interface"

//Components
import Footer from "../components/utility/footer"
import ShellModal from "../components/modal/modal"
import AlertDialog from "../components/utility/alert"
import PayrollFragmentedBody from "../components/modal/body/modal.fragment.payroll"
import ModalPromptBody from "../components/modal/modal.prompt"
import PayrollTopView from "../components/payroll/view/payroll.top.view"
import TopTableBar from "../components/utility/top.table.bar"
import TblV2 from "../components/table/table.v2"
import PayrollInputs from "../components/payroll/form/payroll.inputs"

//Context
import { ThemeContext , MainContext } from "../context/context"
import { SocketContext } from "../context/socket.context"

//Utils
import { f_get_local_key , f_empty_promise , f_set_local_key, f_encode_query_data , CheckEnv } from "../utils/utility"
import { f_fetch } from "../api/fetch"


//Constants
import { default_filter, empty_pay, pay_errors } from "../utils/constant"
import SearchFilter from "../components/utility/inputs/search.filter"


//Styles
import { header_row, tbl_boxing } from "../styles/tbl.styles"
import { i_complete_pay, i_pay_errors, i_payroll_input_props } from "../interfaces/payroll.interface"

//Constants
const Payroll = ( props : i_initial_props ) : ReactElement => {

    const mock = [ { user_id : 123456789012345 , status : "Paid" , start : 21312322 , end : 33992938 , hours : 38.9 , raw_pay : 3982 , net_pay : 2309 , tax : 500  }]
    const { mode } = useContext(ThemeContext);
    const { HandleLogout } = useContext(MainContext)
    const socket = useContext(SocketContext);
    const config = f_get_local_key('config') 
    const nav = useNavigate();
    const [ dev ] = useState<boolean>(CheckEnv);
    
    const [ data , setData ] = useState<i_complete_pay[]>([])
    const [ users , setUsers ] = useState<i_user[]>([])
    const [ count , setCount ] = useState<number>(1)
    const [ search , setSearch ] = useState<string>("")
    const [ acc_stats_open , setAccStatsOpen ] = useState(config.stats_payroll)
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter)
    const [ api_errors, setApiError ] = useState({ open : false, promise : f_empty_promise()})
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ modal_type ] = useState<t_transition_event>('delete')
    const [ target, setTarget ] = useState<any>({})
    const [ open, setOpen ] = useState<boolean>(false)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ assets , setAssets ] = useState<any>([])
    const [ uploads , setUploads ] = useState<any>([])

    //Form states
    const [ pay , setPay ] = useState<i_complete_pay>(empty_pay)
    const [ pays_errors ] = useState<i_pay_errors>(pay_errors)
    const [ Euploads , setEuploads ] = useState<string>("")

    useEffect(() => {
        socket.removeAllListeners("job");
        socket.on('job', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_complete_pay ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_complete_pay , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            console.log(ItemUpdated)
                            return ItemUpdated
                        } else {
                            return item
                        }
                    })
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_complete_pay ) => {
                    const data_to_update = [ ...data ]
                    const objectExists = data_to_update.some(( obj : i_complete_pay ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        data_to_update.push(ItemAdded)
                        setData(data_to_update)    
                        setCount(count +1 )
                    }
                } 
                addRow(output.item)
            }
        })
    },[socket , data , count])

    useEffect(() => {
        const f_get_users = async () => {
            const res = await f_fetch('/users', 'GET', true, null)
            if(res.type === 'Success')  setUsers(res.data)
        }
        f_get_users()
    },[])
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const query = f_encode_query_data(filter)
            const res = await f_fetch('/payrolls/filtered?'+query , 'GET' , true , null)
            if(res.type === 'Success'){
                setData(res.data.payrolls)
                setCount(res.data.count)
            }else{
                if(res.type === 'Unauthorized'){
                    HandleLogout(nav)
                }else{
                    setApiError({ open : true, promise : res})
                }
            }
            setLoading(false)
        }
        fetchData()
    },[HandleLogout , nav , filter])
    const handleModalAction = async() => {

    }
    const f_render_title = () : string => {
        var title : string = ''
        title = modal_type === 'edit_profile' ? title = 'Edit ' + target.title + ' profile' : "to be defined"
        return title
    }
    const handleAccordion = ( type : string ) : void => { 
        const current_cookie = f_get_local_key('config')
        const new_cookie = { ...current_cookie}
        if(type === 'stats') {
            new_cookie.stats_payroll = acc_stats_open === true ? false : true
            f_set_local_key('config', new_cookie ,  1000 * 60 * 60 * 24)
            setAccStatsOpen(acc_stats_open === true? false : true)
        }
    }
    const handlePeriod = () : string => {
        const date = new Date();
        const currentDay = date.getDay();
        const mondayDate = new Date(date);
        mondayDate.setDate(date.getDate() - currentDay + (currentDay === 0 ? -6 : 1));
        const fridayDate = new Date(mondayDate);
        fridayDate.setDate(mondayDate.getDate() + 4);
        const mondayDateString = mondayDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' , year : 'numeric'});
        const fridayDateString = fridayDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' , year : 'numeric'});

        return `Period: ${mondayDateString} - ${fridayDateString}`;
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const handleSubmit = async() => {
    }
    const top_view_props = {
        acc_open : acc_stats_open,
        handleAcc : handleAccordion,
        pay_total : 13099,
        net_pay : 8500,
        total_taxes : 1293,
        pay_count: 12,
        period : handlePeriod(),
        turn_over : 2420,
        turn_over_plus : 420,
        turn_over_last_month : '40%',
        today_task_completion : '60%',
        today_task_vs_yesterday : '15%',
        week_task_completion : '45%',
        sastifaction_score : 416,
        high_rating : 500,
        mid_rating : 400,
        low_rating : 200,
        total_ratings : 1100,
        global_rating : 4.5
    }
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiError({open : false , promise :f_empty_promise()}),
        type : 'simple'
    }
    const fragmented_body_props : i_fragmented_body = {
        form : {},
        modal_type : modal_type,
    }
    const shell_modal_props : i_shell_modal = {
        open : open,
        children : <PayrollFragmentedBody {...fragmented_body_props} />,
        handleClose : () => setOpen(false),
        title : f_render_title(),
        mode : mode
    }
    const modal_prompt_props : i_prompt_modal_props = {
        open : open,
        handleClose : () => setOpen(false),
        title : target.title,
        mode : mode,
        callback : handleModalAction,
        loading : loading,
        type : modal_type
    }
    const handleRow = ( value : any , type : t_display ) : void => {
        setTarget(value)
 
    }
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Payrolls',
        callback : () => setDisplay('form'),
        add : true,
        inputs : [<SearchFilter {...search_input_props} />]
    }
    const payroll_headers : i_tbl_header[] = [
        { value : "Employee name" , css : { ...header_row, width : '15%' } , portion : 4 ,  icon: (
            <IconButton 
                onClick={() => setFilter({...filter, sort_order: filter.sort_order === 'ASC' ? 'DESC' : 'ASC'})}
            >
                <img 
                    alt="Sort order img" 
                    src={filter.sort_order === 'ASC' ? './images/arrow-up.svg' : './images/arrow-down.svg'}
                />
            </IconButton>
        )},
        { value : "Pay status" , css : { ...header_row, width : '20%' } , portion : 1.5 },
        { value : "Period" , css : { ...header_row, width : '20%' } , portion : 2 },
        { value : "Hours" , css : { ...header_row, width : '15%' } , portion : 1},
        { value : "Net pay" , css : { ...header_row, width : '10%' } , portion : 1 },
        { value : "Taxes" , css : { ...header_row, width : '10%' } , portion : 1 },
        { value : 'Action', css : header_row, portion : 1.5  }
    ]
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
        const new_filter = { ...filter }
        new_filter.page = page
        setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Payrolls'
    } 
    const table_v2_props : i_table_v2_props = {
        data : mock,
        title : 'Please start by adding a new payroll',
        loading : loading,
        headers : payroll_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "payroll",
        pagination :  pagination_props
    }
    const file_uploads : i_file_uploads_props = {
        uploads : uploads,
        setUploads : setUploads,
        assets : assets,
        setAssets: setAssets,
        Euploads : Euploads,
        setEuploads: setEuploads,
        type : 'form',
        path : '/webapi/uploads/jobs/',
        title : 'File uploads',
        setApiError: setApiError,
        max : 20,
        mobile : props.mobile,
        file_type : 'photo',
        language : props.language
    }
    const payroll_inputs_props : i_payroll_input_props = {
        form : pay,
        setForm : setPay,
        errors : pays_errors,
        mode : mode,
        uploads : file_uploads,
        callback : handleSubmit,
        onClose : setDisplay,
        title : '',
        loading : loading,
        dev : dev,
        users : users,
    }
    return (
        <Box>
            <Box sx={{ minHeight : '91.5vh'}}>
            {display === 'table' &&
                <Box >
                   <PayrollTopView { ...top_view_props }></PayrollTopView>
                   <TopTableBar {...table_tool_bar_props}></TopTableBar>
                   <Box sx={ tbl_boxing }>
                        <TblV2 { ...table_v2_props }></TblV2>
                   </Box>
                </Box>
            }
            {display === 'form' && 
                <Box>
                   <PayrollInputs {...payroll_inputs_props}></PayrollInputs>
                </Box>
            }
            </Box>
            <AlertDialog {...alert_props} />
                <Modal open={open} onClose={() => setOpen(false)} children={
                    (modal_type === 'delete' || modal_type === 'enable' || modal_type === 'disable') ? <ModalPromptBody {...modal_prompt_props}/>
                : <ShellModal {...shell_modal_props}/>}></Modal>
            <Footer type={'off'}/>
        </Box>
    )
}


export default Payroll