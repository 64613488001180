
//Main planner
const P_1 = {
    "Events Planner" : { en : "Garage events management" , fr : "Planificateur d'évenement du garage" },
    "Next" : { en : "Next", fr : "Suivant" },
    "Back" : { en : "Back", fr : "Précédent" },
    "Today" : { en : "Today", fr : "Aujourd'hui" },
    "Month" : { en : "Month", fr : "Mois" },
    "Week" : { en : "Week", fr : "Semaine" },
    "Day" : { en : "Day", fr : "Jour" },
    "Hour" : { en : "Hour", fr : "Heure" },
    "Minute" : { en : "Minute", fr : "Minute" },
    "Time" : { en : "Time", fr : "Heure" },
    "Event" : { en : "Event", fr : "Événement"}
   }

//Planner inputs
   const Pi_1 = {
    "Title" : { en : "Title" , fr : "Titre" },
    "Description" : { en : "Description", fr : "Description" },
    "Start date" : { en : "Start date", fr : "Date de début"},
    "End date" : { en : "End date", fr : "Date de fin" },
    "Type" : { en : "Type", fr : "Type" },
    "Color" : { en : "Color", fr : "Couleur" },
    "User" : { en : "Select a user", fr : "Selectioné un utilisateur" },
    "Delete" : { en : "Delete", fr : "Supprimer" },
}
export { P_1 , Pi_1 }