/*
 *
 * Date: 2024
 * Description: Tab for events planning
 * Author: Philippe Leroux @SKITSC
 */

//Modules
import { useState , useEffect , useContext, useMemo, ReactElement , useCallback } from "react";
import { Box , Typography , Modal , Grid , Switch, Tooltip, TextField , MenuItem , Button } from "@mui/material";
import { Calendar, dayjsLocalizer} from "react-big-calendar";
import dayjs from 'dayjs';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from "moment";
import { useNavigate } from "react-router-dom";

//Context
import { SocketContext } from "../context/socket.context";
import { ThemeContext } from "../context/context";
import { MainContext } from "../context/context";

//Components
import CircularUnderLoad from "../components/utility/center.loader";
import PlannerInputs from "../components/planner/planner.inputs";
import ShellModal from "../components/modal/modal";
import AlertDialog from "../components/utility/alert";
import ModalPromptBody from "../components/modal/modal.prompt";

//Api
import { f_fetch } from "../api/fetch";

//Interface && types
import { i_user } from "../interfaces/user.interface";
import { t_event, t_lang, t_method } from "../types/types";
import { i_planner_inputs , i_cal_event, i_cal_event_errors, i_cal_data } from "../interfaces/planner.interface";
import { i_prompt_modal_props, i_shell_modal, i_snack_alert, i_socket_response , i_alert_props, i_initial_props } from "../interfaces/utility.interface";

//Styles
import "../styles/calendar.css";

//Utility
import { delay, f_empty_promise, f_return_color, f_set_first_letter , f_darken_color , f_lighten_hex_color } from "../utils/utility";

//Translations
import { P_1 } from "../components/planner/planner.translation";

//Middlewares
import { m_validate_colors, m_validate_date, m_validate_empty_fields, m_validate_str } from "../validation/utility.middleware";
import { m_validate_planner } from "../validation/main.middleware";

//Constants
import { cal_view_selection , empty_cal_event , cal_event_errors } from "../utils/constant";

//Icons
import AddIcon from '@mui/icons-material/Add';

const DnDCalendar = withDragAndDrop(Calendar);

const Planner = ( props : i_initial_props ) : ReactElement => { 

    const language : t_lang = 'en'
    const L : t_lang = language
    const Nav = useNavigate()
    const { user , HandleLogout } = useContext(MainContext)
    const { mode } = useContext(ThemeContext)
    moment.locale( language === 'en' ? 'en-ca' : 'fr-ca' );
    
    const localizer = dayjsLocalizer(dayjs)
    const socket = useContext(SocketContext);

    //Main states
    const [ loading , setLoading ] = useState<boolean>(true);
    const [ data, setData ] = useState<i_cal_data[]>([]);
    const [ trigger , setTrigger ] = useState<boolean>(false);
    const [ trigger_del , setTriggerDel ] = useState<boolean>(false);
    const [ event , setEvent ] = useState<t_event>('Add');
    const [ users , setUsers ] = useState<i_user[]>([])
    const [ event_loading , setEventLoading ] = useState<boolean>(false);
    const [ delete_loading, setDeleteLoading ] = useState<boolean>(false);
    const [ api_error , setApiError ] = useState<i_snack_alert>({ open : false , promise : f_empty_promise()});
    const [ working_hours , setWorkingHours ] = useState<boolean>(true)

    //Form fields
    const [ cal_event , setCalEvent ] = useState<i_cal_event>(empty_cal_event)

    //Form errors
    const [ cal_errors , setCalEventErrors ] = useState<i_cal_event_errors>(cal_event_errors)

    //Calendar states
    const [ cal_view , setCalView ] = useState<string>('month');
    const f_format_arr = ( arr : i_cal_event[] ) => {
        const new_events : i_cal_data[] = arr.map((item : i_cal_event) => ({
            ...item, start : new Date(item.start), end : new Date(item.end)
           }))
        return new_events
    }

    const handleWorkingHours = (e : any) => {
        setWorkingHours(e)
    }
    const onView = useCallback((newView : string) => {
        setCalView(newView)
    }, [setCalView])

    useEffect(() => {
        setLoading(true)
        const handle_fetch = async() => {
            const events = await f_fetch('/events' , 'GET' , true , null)
            if(events.type === 'Success'){
                const formated_events = f_format_arr(events.data)
                setData(formated_events)
            }
            if(events.type === 'Unauthorized') HandleLogout(Nav)
            const res_users = await f_fetch('/users' , 'GET' , true , null)
            if(res_users.type === 'Success') setUsers(res_users.data)
            if(events.type === 'Unauthorized')  HandleLogout(Nav)
            setLoading(false)
        }
        handle_fetch()
    },[ HandleLogout , Nav])
    const handleSelectEmptySlot = useCallback(() => {
        const clean_target = { ...empty_cal_event, user_id : user._id }
        handleTarget(clean_target , 'Add')
        setTrigger(true)
    },[setTrigger , user])
    const CustomToolbar = useCallback((props : any) => {
        const handleNav = (nav : string) => {
            props.onNavigate(nav);
        }
        const handleCalView = ( view : string) => {
            props.setCalView(view)
            props.onView(view);
        }
        return (
          <div>
            <Box sx={{ display : 'flex' , marginLeft : '15px' , marginBottom : '1vh' , marginTop : '1vh'}}>
                <Box onClick={() => handleNav('PREV')} sx={{border : '1px solid gray' , cursor : 'pointer' , display : 'flex' , justifyContent : 'center' , padding : '5px' , borderTopLeftRadius : '5px' , borderBottomLeftRadius : '5px'}}>
                    <Box component={'img'} alt="prev-icon" src={'./images/arrow-left.svg'}></Box>
                </Box>
                <Box onClick={() => handleNav('TODAY')} sx={{border : '1px solid gray' , cursor : 'pointer' , display : 'flex' , justifyContent : 'center' , padding : '5px'}}>
                    <Box component={'img'} alt="prev-icon" src={'./images/home-04.svg'}></Box>
                </Box>
                <Box onClick={() => handleNav('NEXT')} sx={{border : '1px solid gray' , cursor : 'pointer' , display : 'flex' , justifyContent : 'center' , padding : '5px'}}>
                    <Box component={'img'} alt="prev-icon" src={'./images/arrow-right.svg'}></Box>
                </Box>
                <Typography sx={{ alignSelf : 'center' , fontSize : 24 , paddingLeft : '2.5%' , paddingRight : '2.5%'}}>{props.label}</Typography>
                <TextField size={'small'} value={props.view} select onChange={(e) => handleCalView(e.target.value)}>
                    { cal_view_selection.map(( item , index ) => (
                        <MenuItem key={index} value={item}>
                            {f_set_first_letter(item)}
                        </MenuItem>
                    ))}
                </TextField>
                <Tooltip title={working_hours ? 'Switch to all hours' : 'Switch to working hours'}>
                    <Switch size={"medium"} aria-label="Switch working hours" defaultChecked={true} onChange={(e) => { handleWorkingHours(e.target.checked)}}></Switch>
                </Tooltip>
                <Typography sx={{ alignSelf : 'center'}}>Show opening hours only</Typography>
                <Button onClick={() => handleSelectEmptySlot()} startIcon={<AddIcon/>} sx={{ marginLeft : 'auto' , marginRight : '10px'}} variant="contained">
                    Add event
                </Button>
            </Box>
          </div>
        );
      },[handleSelectEmptySlot, working_hours]);

    useEffect(() => {
        socket.removeAllListeners("event");
        socket.on('event', ( output : i_socket_response ) => {
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_cal_data ) => {
                    const data_to_update = [ ...data ]
                    const objectExists = data_to_update.some(( obj : i_cal_data ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        ItemAdded.end = new Date(ItemAdded.end)
                        ItemAdded.start = new Date(ItemAdded.start)
                        data_to_update.push(ItemAdded)
                        setData(data_to_update)    
                    }
                }    
                addRow(output.item)
            }
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_cal_data ) => {
                    const data_to_update = [ ...data ] 
                    const updatedItems = data_to_update.map( ( item : i_cal_data , i : number) => {
                        if (item._id === ItemUpdated._id) {
                            ItemUpdated.end = new Date(ItemUpdated.end)
                            ItemUpdated.start = new Date(ItemUpdated.start)
                            return ItemUpdated
                        } else {
                        return item
                        }
                    })
                    setData(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Delete'){
                const DeleteItem = ( ItemDeleted : i_cal_data ) => {
                    const data_to_delete = [ ...data ] 
                    const index = data_to_delete.findIndex(( row : i_cal_data ) => row._id === ItemDeleted._id);
                    if(index > -1){
                        data_to_delete.splice(index,1)
                        setData(data_to_delete)
                    }
                } 
                DeleteItem(output.item)
            }
        })
    },[socket , data])
    useEffect(() => {
        const F = cal_event
        const E = cal_errors
        if( E.description !== ''  &&  m_validate_str(F.description)) setCalEventErrors({...cal_errors, description : ''})
        if( E.title !== '' && m_validate_empty_fields(F.title) && m_validate_str(F.title) ) setCalEventErrors({...cal_errors, title : ''})
        if( E.start !== '' && m_validate_date(F.start)) setCalEventErrors({...cal_errors, start : ''})
        if( E.end !== '' && m_validate_date(F.end)) setCalEventErrors({...cal_errors, end : ''})
        if( E.color!== '' && m_validate_colors(F.color)) setCalEventErrors({...cal_errors, color : ''})
        if( E.user_id !== '' && m_validate_str(F.user_id) ) setCalEventErrors({...cal_errors, user_id : ''})
        if( E.order_number!== '' && m_validate_str(F.order_number) ) setCalEventErrors({...cal_errors, order_number : ''})
        const handleDate = () : boolean => {
            if(F.start > F.end) return false
            return true
        }
        if( E.start !== '' && handleDate()) setCalEventErrors({...cal_errors, start : ''})
        if( E.end !== '' && handleDate()) setCalEventErrors({...cal_errors, end : ''})
        if( E.color !== '' && Number(F.color)) setCalEventErrors({...cal_errors, color : ''})
    },[cal_event , cal_errors])

    const handleEventDrop = async({ event, start, end } : any) => {
        const res = await f_fetch(`/event/${event._id}/${start}/${end}`, 'PATCH', true, event)
        if(res.type === 'Success') {
            setData((prevEvents) => {
                const updatedEvents = prevEvents.map((ev) => {
                  if (ev._id === event._id) {
                    return { ...ev, start, end };
                  }
                  return ev;
                });
                return updatedEvents;
              });
        }else{
            //Handle error
            console.log('Error with the drag and drop event')
        }
      
    };      
    
    const handleEventClick = (event : any) => {
        handleTarget(event , 'Update')
        setTrigger(true)
    };
    const handleTarget = ( target : i_cal_event , type : t_event ) : void => {  
        setCalEvent({ ...target})
        setEvent(type)
    }
    const handleSubmit = async() : Promise<void> => {
        setEventLoading(true)
        var method : t_method = 'POST'
        if(event === 'Update') method = 'PUT'
        const clean_data = {...cal_event , start : cal_event.start.valueOf(), end : cal_event.end.valueOf()}
        const [ valid , message , field ] = m_validate_planner(clean_data)
        if(valid){
            console.log(cal_event)
            await delay(1000)
            const res = await f_fetch('/event', method , true, clean_data)
            if(res.type === 'Success'){
                setTrigger(false)
            }
            setApiError({ open : true , promise : res})
        }else{
            console.log(valid,message,field)
            setCalEventErrors({...cal_event_errors, [field] : message})
        }
        setEventLoading(false)
       
    }
    const handleDelete = async() => {
        setDeleteLoading(true)
        await delay(1000)
        const res = await f_fetch(`/event/${cal_event._id}`, 'DELETE', true, null)
        if(res.type === 'Success') {
            setTriggerDel(false)
            setTrigger(false)
            handleTarget(empty_cal_event , 'Delete')
        }
        setDeleteLoading(false)
        setApiError({ open : true , promise : res})
    }

    const form_props : i_planner_inputs = {
        form : cal_event,
        setForm : setCalEvent,
        errors : cal_errors,
        callback : handleSubmit,
        event : event,
        users : users,
        lang : L,
        delete_loading : delete_loading,
        loading : event_loading,
        handleDelete : () => setTriggerDel(true),
        handleClose : () => setTrigger(false),
    }
    const modal_props : i_shell_modal = {
        open : trigger,
        handleClose : () => setTrigger(false),
        children : <PlannerInputs {...form_props} />,
        title : event === 'Add'? 'Add a new Event' : 'Update an Event',
        mode : mode
    }
    const modal_del_props : i_prompt_modal_props = {
        open : trigger_del,
        handleClose : () => setTriggerDel(false),
        title : cal_event.title,
        mode : mode,
        callback : handleDelete,
        loading : delete_loading,
        type : 'delete'
    }
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : f_empty_promise() }),
        type : 'simple'
    }

    const workWeekStart = new Date();
    workWeekStart.setHours(6, 0, 0, 0);
    const workWeekEnd = new Date();
    workWeekEnd.setHours(19, 0, 0, 0);


    const EventFormat = (props : any) => {
        return (
            <Grid container sx={{display: 'flex', alignItems: 'center'}}>
                <Grid item xs={12}>
                    <Box sx={{ display : 'flex'  }}>
                        <Box sx={{width: '10px',height: '10px',borderRadius: '5px',backgroundColor: f_return_color(props.event.color),marginRight: '5px', alignSelf : 'center'}}></Box>
                        <Typography sx={{ color :  f_darken_color(f_return_color(props.event.color) , 0.5) , fontWeight : 600}}>{props.event.title}</Typography>
                        {/* { props.cal_view === 'month' &&
                            <Typography sx={{ fontSize : 12 , marginLeft : 'auto' , alignSelf : 'center'}}>{f_make_cute_date(props.event.start)}</Typography>
                        } */}
                    </Box>
                </Grid>
            </Grid>
        )
    }
    const eventPropGetter = (event: any, start: Date, end: Date, isSelected: boolean) => {
        let backgroundColor = '';
        if (cal_view === 'day' || cal_view === 'week' || cal_view ==='agenda') {
          backgroundColor = f_lighten_hex_color(f_return_color(event.color) , 90)
        }
        return {
          style: {
            backgroundColor,
            
          },
        };
    }
    
    const { components, defaultDate } = useMemo(
        () => ({
          components: {
            toolbar: ( props : any) => <CustomToolbar {...props} cal_view={cal_view} setCalView={setCalView} />,
            event: ( props : any ) => <EventFormat {...props} cal_view={cal_view} />,
          },
          defaultDate: new Date(),
        }),
        [cal_view , setCalView , CustomToolbar]
      )
      const viewMapping: { [key: string]: 'month' | 'week' | 'day' } = {
        customMonth: 'month',
        customWeek: 'week',
        customDay: 'day',
      };
    return (
        <Box component={'div'} >
            { loading ? <CircularUnderLoad type={'full'}/> :
                <Box component={'div'} sx={{ height : '90vh'}} >
                        <DnDCalendar
                            components={components}
                            startAccessor={(event : any) => new Date(event.start)}
                            endAccessor={( event : any) => new Date(event.end)}
                            localizer={localizer}
                            events={data}
                            min={working_hours ? workWeekStart : undefined}
                            max={working_hours ? workWeekEnd : undefined}
                            onView={onView}
                            view={viewMapping[cal_view]}
                            defaultView={'month'}
                            defaultDate={defaultDate}
                            onEventDrop={handleEventDrop}
                            selectable
                            onSelectSlot={(slotInfo : any) => {}}
                            onSelectEvent={handleEventClick}
                            messages={{
                                next : P_1['Next'][L],
                                previous : P_1['Back'][L],
                                today : P_1['Today'][L],
                                month : P_1['Month'][L],
                                week : P_1['Week'][L],
                                day : P_1['Day'][L],
                                time : P_1['Time'][L],
                                event : P_1['Event'][L]
                            }}
                            eventPropGetter={eventPropGetter}
                            
                        />
                </Box>
            }
            <AlertDialog {...alert_props} />
            <Modal open={trigger} onClose={() => setTrigger(false)} children={<ShellModal {...modal_props}/>}></Modal>
            <Modal open={trigger_del} onClose={() => setTriggerDel(false)} children={<ModalPromptBody {...modal_del_props}></ModalPromptBody>}></Modal>
        </Box>
    )
}
export default Planner
